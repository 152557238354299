//Подключение полифила для ie
import './polyfill-from'

import Swiper, { Navigation, Pagination, EffectFade }  from "swiper";

Swiper.use([Navigation, Pagination, EffectFade ])

/**********************
 * Скрипты для главной страницы
 *********************/


/****************************
 * Скрипты для страницы about
 * ***************************/

 /* Полоса прокрутки с изменением содержимого */
class Slider {
    private Items;
    private CountVisible;
    private Duration;
    private SliderWidth;
    private AnimationInProgress = false;

    constructor (visibleItems, items, duration=500) {
        this.Items = items;
        this.Duration = duration

        //Проверка количества элементов
        if(visibleItems > items.length)
            this.CountVisible = items.length;
        else
            this.CountVisible = visibleItems;

        //Установить стили родителя
        this.Items[0].parentNode.style.position = "relative";
        this.Items[0].parentNode.style.overflow = "hidden";
        
        this.SliderWidth = Number(getComputedStyle(this.Items[0].parentNode).width.replace("px", ""));
        let obj = this;
        window.addEventListener("resize", function() {obj.sliderSizeChanged(obj);} );

        //Спрятать каждый элемент и установить задержку анимации
        for(let i = 0; i < items.length; i++) {
            if(i >= this.CountVisible)
                this.Items[i].style.visibility = "hidden";
            this.Items[i].style.position = "absolute";
            this.Items[i].style.transition = "left " + duration + "ms ease-out";
        }

        //Расстояние между элементами
        let space = this.spaceBetween(0, this.CountVisible-1);
        
        //Распределить элементы по ширине
        this.Items[0].style.left = 0 + "px";
        for(let i = 1; i < this.CountVisible; i++) {
            let offset = Number(this.Items[i-1].style.left.replace("px",""));
            let widthPrev = Number(getComputedStyle(this.Items[i-1]).width.replace("px", ""));
            this.Items[i].style.left = (offset + widthPrev + space) + "px";
        }
    }

    /*
    Вычислить расстояние между объектами
    */
    private spaceBetween(startIndex, endIndex) {
        var parent = this.Items[0].parentNode;
        //Расстояние занятое объектами
        let filledSpace = 0;
        for (let i = startIndex; i <= endIndex; i++) {
            filledSpace += Number(getComputedStyle(parent.children[i]).width.replace("px", ""));
        }

        return (this.SliderWidth - filledSpace)/(this.CountVisible-1);
    }
    

    back() {
        //Пропустить некоторые обработчики если предыдущая анимация не закончилась
        if(this.AnimationInProgress) return;
        this.AnimationInProgress = true;
        var parent = this.Items[0].parentNode;


        //Расстояние между элементами
        let space = this.spaceBetween(1, this.CountVisible);
        
        //Сместить последний элемент вправо чтобы он плавно выехал оттуда
        parent.children[this.CountVisible].style.left = (Number(getComputedStyle(parent).width.replace("px", "")) + space) + "px";
        parent.children[this.CountVisible].style.visibility = "visible";

        //Запустить анимацию для всех элементов
        parent.firstElementChild.style.left = -(Number(getComputedStyle(parent.firstElementChild).width.replace("px", ""))+space) + "px";
        for(let i = 1; i <= this.CountVisible; i++) {
            let offset = Number(parent.children[i-1].style.left.replace("px",""));
            let widthPrev = Number(getComputedStyle(parent.children[i-1]).width.replace("px", ""));
            parent.children[i].style.left = (offset + widthPrev + space) + "px";
        }

        //После окончания анимации переместить элементы и убрать изменения
        let obj = this;
        setTimeout(function() {
            parent.appendChild(parent.removeChild(parent.firstElementChild));
            parent.lastElementChild.style.visibility = "hidden";
            parent.lastElementChild.style.left = null;
            obj.AnimationInProgress = false;
        }, this.Duration);
    }


    forward() {
        //Пропустить некоторые обработчики если предыдущая анимация не закончилась
        if(this.AnimationInProgress) return;
        this.AnimationInProgress = true;
        var parent = this.Items[0].parentNode;

        //Подгрузить дополнительный слайд с конца
        parent.insertBefore(parent.removeChild(parent.lastElementChild), parent.firstElementChild);

        //Расстояние между элементами
        let space = this.spaceBetween(0, this.CountVisible-1);
        
        //Переместить последний элемент влево, чтобы он плавно выехал оттуда
        parent.firstElementChild.style.left = -(Number(getComputedStyle(parent.firstElementChild).width.replace("px", "")) + space ) + "px";
        parent.firstElementChild.style.visibility = "visible";


        //Запустить анимацию для всех элементов
        parent.children[this.CountVisible].style.left = (Number(getComputedStyle(parent).width.replace("px", "")) + space) + "px";
        for(let i = this.CountVisible-1; i >= 0; i--) {
            let offset = Number(parent.children[i+1].style.left.replace("px",""));
            let width = Number(getComputedStyle(parent.children[i]).width.replace("px", ""));
            parent.children[i].style.left = (offset - width - space) + "px";
        }
        //После окончания анимации переместить элементы и убрать изменения
        let obj = this;
        setTimeout(function() {
            parent.children[obj.CountVisible].style.visibility = "hidden";
            parent.children[obj.CountVisible].style.left = null;
            obj.AnimationInProgress = false;
        }, this.Duration);

    }

    private sliderSizeChanged(object) {
        object.SliderWidth = getComputedStyle(object.Items[0].parentNode).width.replace("px","");
        //Расстояние между элементами
        let space = object.spaceBetween(0, object.CountVisible-1);
        //Распределить элементы по ширине
        let parent = object.Items[0].parentNode;
        parent.firstElementChild.style.left = 0 + "px";
        for(let i = 1; i < object.CountVisible; i++) {
            parent.children[i].style.transition = null;
            let offset = Number(parent.children[i-1].style.left.replace("px",""));
            let widthPrev = Number(getComputedStyle(parent.children[i-1]).width.replace("px", ""));
            parent.children[i].style.left = (offset + widthPrev + space) + "px";
            parent.children[i].style.transition = "left " + object.Duration + "ms ease-out";
        }
    }

    setActive(item) {
        if(item.classList.contains("active"))
            return;
        else
            item.classList.add("active");
    }

    unsetActive(item) {
        if(!item.classList.contains("active"))
            return;
        else
            item.classList.remove("active");
    }
}

/* Слайдер типа радиокнопок */
class RadioSlider {
    Items;
    ActiveItem;
    TimerId;
    Delay;
    /*Delay - задержка перед автоматическим изменением слайдера*/
    constructor(delay, items) {
        if(items.length <= 1) return;
        this.Items = items;
        this.Delay = delay;
        this.switchTo(this.Items[0]);
    }

    switchTo(item) {
        if(this.ActiveItem != null && this.ActiveItem != undefined) {
            this.unsetActive(this.ActiveItem);
        }
            
        this.setActive(item);
        this.ActiveItem = item;
        var obj = this;
        //Установка таймера снова
        if(this.Delay !=null && this.Delay != undefined && this.Delay > 0) {
            clearInterval(obj.TimerId);
            obj.TimerId = setInterval(function() {
                if(obj.ActiveItem.nextElementSibling != null)
                    obj.switchTo(obj.ActiveItem.nextElementSibling);
                else
                    obj.switchTo(obj.Items[0]);
            }, this.Delay);
        } 
    }
    
    setActive(item) {
        if(item.classList.contains("active"))
            return;
        else {
            const elementIndex = this.getElementIndex(item);

            if (item.parentNode.nextElementSibling) {
                item.parentNode.nextElementSibling.children[elementIndex].classList.add('active');
            }

            item.classList.add("active");
        }
    }

    unsetActive(item) {
        if(!item.classList.contains("active"))
            return;
        else {
            const elementIndex = this.getElementIndex(item);
            if(item.parentNode.nextElementSibling) {
                item.parentNode.nextElementSibling.children[elementIndex].classList.remove('active');
            }
            item.classList.remove("active");
        }
            
    }

    getElementIndex(elem) {
        elem = elem.tagName ? elem : document.querySelector(elem);
        return [].indexOf.call(elem.parentNode.children, elem)
    }
}

//Ждать полной загрузки
const EMPLOYEES = document.querySelectorAll('.employees ol li');
const SLIDER = document.querySelector('.employees ol');

window.addEventListener("load", function() {
    if(EMPLOYEES.length > 0) {
        let slider = new Slider(5, EMPLOYEES);
        let buttonBack = document.querySelector(".button.forward")
        buttonBack.addEventListener("click", function() {
            slider.back();
        }, false);
        let buttonForward = document.querySelector(".button.back")
        buttonForward.addEventListener("click", function() {
            slider.forward();
        }, false);
    }

    const employeesSlider = new Swiper('.employees-slider', {
        direction: 'horizontal',
        loop: true,
        slidesPerView: 5,
        centeredSlides: false,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })

    const productSwiper = new Swiper('.product-slider', {
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        speed: 1000,
        simulateTouch: false,
        pagination: {
            el: '.product-slider__control-list-item',
            type: 'bullet',
            bulletElement: 'div',
            clickable: true,
            bulletActiveClass: 'active'
        },
        on: {
            slideChange: function() {
                const activeSlideIndex = this.snapIndex;
                const prevSlideIndex = this.previousIndex;
                const bullets = document.querySelectorAll('.product-slider__control-list-item');
                
                bullets[prevSlideIndex].classList.remove('active');
                bullets[activeSlideIndex].classList.add('active');
            }
        }
    });
});



// Слайдер на странице about
const BUTTONS = document.querySelectorAll('.time-line .button.control');

if(BUTTONS.length > 0) {
    var radioSlider = new RadioSlider(5000, BUTTONS);
    //attach handlers
    for (var i = 0; i < BUTTONS.length; i++) {
        (function(n) {
            var item = BUTTONS[i];
            BUTTONS[n].addEventListener("click", function() {
                radioSlider.switchTo(BUTTONS[n]);
            }, false);
        }(i));
    }
}

//columns
const column: Array<Element> = Array.from(document.querySelectorAll('.gistogramm .chart .column'))
const colValue: Array<Element> = Array.from(document.querySelectorAll('.gistogramm .chart .column .value'))
let colTitle: Array<Element> = Array.from(document.querySelectorAll('.gistogramm .chart .column .title'))
let columnMaxValue: Array<String> = []
const chart: Element = document.querySelector('.chart')

const delayArray: Array<Number> = [0, 250, 500, 750, 1000]
const deltaArray: Array<Number>  = [30, 10, 15, 10, 10]


column.forEach(el => {
    columnMaxValue.push(el.getAttribute('max-value'))
})

window.onscroll = () => {
    if(chart) {
        if(elementInViewport(chart)){
            animateColumn(true)
        }
    }
}

window.onload = () => {
    if(chart) {
        if(elementInViewport(chart)){
            animateColumn(true)
        }
    }
}

function animateColumn(flag) {
    column.forEach((col, index) => {
        incrValue(deltaArray, index, <HTMLElement[]>colTitle, delayArray, colValue, flag)      
    })
}


function getText(colTitle) {
    let array = []
    colTitle.forEach(el => {
        array.push(el.innerText)
    })

    return array
}

function incrValue(delta, index, title, delayTimeArray, column, addFlag) {
    let timerTimeOut = setTimeout(() => {
        let timerInterval = setInterval(() => {
            let colTitleTextArray = getText(title)
            if(parseInt(columnMaxValue[index].toString()) > parseInt(colTitleTextArray[index].toString())) {
                (<HTMLElement>colTitle[index]).innerText = parseInt(colTitleTextArray[index]) + delta[index]
            } else {
                (<HTMLElement>colTitle[index]).innerText = columnMaxValue[index].toString()
                clearInterval(timerInterval)
            }
        }, 1)
        if(addFlag) {
            column[index].classList.add('active')
        } else {
            if(column[index].classList.contains('active')) {
                column[index].classList.remove('active')
            }
        }
    }, delayTimeArray[index])
}

//viewport
function elementInViewport(el) {
  let top = el.offsetTop;
  let left = el.offsetLeft;
  let width = el.offsetWidth;
  let height = el.offsetHeight;

  while(el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top < (window.pageYOffset + window.innerHeight) &&
    left < (window.pageXOffset + window.innerWidth) &&
    (top + height / 4) > window.pageYOffset &&
    (left + width) > window.pageXOffset
  );
}


//send form
function sendCV() {
    let file = document.querySelector('input[type=file]').files[0]
    let position = document.querySelector('input[name=position]').value
    let name = document.querySelector('input[name=first-name]').value
    let comment = document.querySelector('textarea[name=comment]').value
    let lockContainer: Element = document.querySelector('.lock-container')
    let fileName: Element = document.querySelector('.fileName')

    lockContainer.classList.add('show');

    let formData = new FormData()
    formData.append('file', file)
    formData.append('position', position)
    formData.append('name', name)
    formData.append('comment', comment)

    let success = document.querySelector('.success')
    let error = document.querySelector('.error')
    let request = new XMLHttpRequest()
    request.open('POST', '/news/job_form/', true)
    request.onreadystatechange = function () {
        if(request.readyState === 4 && request.status === 200) {
            let json = JSON.parse(request.responseText)
            if(json) {
                success.classList.add('show')
                if(error.classList.contains('show')){
                    error.classList.remove('show')
                }

                let timer = setTimeout(function() {
                    success.classList.remove('show')
                    lockContainer.classList.remove('show');
                    const fields: Array<Element> = Array.from(document.querySelectorAll('input'))
                    const field: Element  = document.querySelector('textarea')
                    fields.forEach(el => {
                        el.value = ''
                    })
                    field.value = ''
                    fileName.innerText = 'Файл не выбран'
                }, 3000)
            } else {
                error.classList.add('show')
                if(success.classList.contains('show')){
                    success.classList.remove('show')
                }

                let timer = setTimeout(function() {
                    error.classList.remove('show')
                    lockContainer.classList.remove('show');
                }, 3000)
            }
        }
        else {
                error.classList.add('show')
                if(success.classList.contains('show')){
                    success.classList.remove('show')
                }

                let timer = setTimeout(function() {
                    lockContainer.classList.remove('show');
                    error.classList.remove('show')
                }, 3000)
        }
    }
    request.send(formData)
}


function sendContact(event) {
    event.preventDefault();

    const form = document.querySelector('.feedback-form__wrapper');
    const success: Element = document.querySelector('.feedback-form__success');
    const error: Element = document.querySelector(' .feedback-form__error');
    const lockContainer: Element = document.querySelector('.lock-container');
    form.classList.add('hide');
    lockContainer.classList.add('show');

    const contact = document.querySelector('input[name=contact]');
    // const name = document.querySelector('input[name=first-name]').value;
    // const comment = document.querySelector('textarea[name=comment]').value;

    const formData = new FormData();
    if (contact.value) {
        formData.append('contact', contact.value);
    }

    // formData.append('name', name);
    // formData.append('comment', comment);

    let request: XMLHttpRequest = new XMLHttpRequest();
    request.open('POST', '/news/contact_form/', true);
    request.onreadystatechange = function () {
        if(request.readyState === 4 && request.status === 200) {
            var json = JSON.parse(request.responseText);

            if(json) {
                success.classList.add('show');
                if(error.classList.contains('show')){
                    error.classList.remove('show')
                }
                let timer = setTimeout(() => {
                    success.classList.remove('show');
                    lockContainer.classList.remove('show'); 
                    const fields: Array<Element> = Array.from(document.querySelectorAll('input'));
                    const field: Element  = document.querySelector('textarea');
                    fields.forEach(el => {
                        el.value = ''
                    });
                    field.value = ''
                }, 2000)
            } else {
                lockContainer.classList.remove('show');
                error.classList.add('show');

                if(success.classList.contains('show')){
                    success.classList.remove('show')
                }
                let timer = setTimeout(() => {
                    error.classList.remove('show');
                    lockContainer.classList.remove('show');
                    form.classList.remove('hide');
                }, 2000)
            }
        }
        else {
            lockContainer.classList.remove('show');
            error.classList.add('show');
            if(success.classList.contains('show')){
                success.classList.remove('show')
            }
            let timer = setTimeout(() => {
                error.classList.remove('show');
                lockContainer.classList.remove('show');
                form.classList.remove('hide');
            }, 2000);
        }
    };
    request.send(formData)
}


const sendButton = document.querySelector('.career-form-resume__send-button');
if(sendButton !== null)
    sendButton.addEventListener('click', sendCV, false);

const sendButtonContact: Element = document.querySelector('.feedback-form__button');
if(sendButtonContact) {
    sendButtonContact.addEventListener('click', sendContact, false)
}


//file
const fileButton:Element = document.querySelector('form .buttons .resume');
const fileName: Element = document.querySelector('.fileName');
if(fileButton) {
    fileButton.addEventListener('change', () => {
        if(fileButton.files[0].name !== '' && fileButton.files[0].name!==null){
            fileName.innerHTML = 'Прикреплено: ' + '<strong>' + fileButton.files[0].name + '</strong>'
        } else {
            fileName.innerText = 'Файл не выбран'
        }
            
    }, false)
}